import dayjs from 'dayjs';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { getIotOssHost } from '@/services/oss/oss';
import {
  errorList,
  warnList,
} from '@/views/iot/general-view/safe-operation/event';

/** @name 相机移动实现漫游等动画 */
export const animateCamera = (
  camera,
  controls,
  newP,
  newT,
  time = 2000,
  callBack,
) => {
  const tween = new TWEEN.Tween({
    x1: camera.position.x,
    y1: camera.position.y,
    z1: camera.position.z,
    x2: controls.target.x,
    y2: controls.target.y,
    z2: controls.target.z,
  });

  tween.to(
    {
      x1: newP.x,
      y1: newP.y,
      z1: newP.z,
      x2: newT.x,
      y2: newT.y,
      z2: newT.z,
    },
    time,
  );

  tween.onUpdate(obj => {
    camera.position.x = obj.x1;
    camera.position.y = obj.y1;
    camera.position.z = obj.z1;
    controls.target.x = obj.x2;
    controls.target.y = obj.y2;
    controls.target.z = obj.z2;
    controls.update();
  });

  tween.onComplete(() => {
    controls.enabled = true;
    callBack();
  });

  // tween.easing(TWEEN.Easing.Cubic.InOut);
  tween.easing(TWEEN.Easing.Circular.InOut);
  tween.start();
};

/** @name 3D 文字 */
class LabelElement {
  #parentBox;
  #list = [];

  setParent(el) {
    this.#parentBox = el;
  }

  get data() {
    return this.#list;
  }
  addArray(el) {
    if (Array.isArray(el)) {
      el.forEach(v => {
        this.#list.push(v);
        this.#parentBox.appendChild(v.elem);
      });
    }
  }
  add(elementName, html, position, option) {
    const el = this.#list.find(v => v.name === elementName);
    if (el) {
      document.getElementById(elementName).style.display = 'block';
      document.getElementById(elementName).innerHTML = html;
      el.position = position;
    } else {
      const elem = document.createElement('div');
      elem.id = elementName;
      elem.innerHTML = html;
      if (option) {
        Object.keys(option).forEach(v => {
          elem[v] = option[v];
        });
      }

      this.#list.push({
        elem,
        name: elementName,
        position,
      });
      this.#parentBox.appendChild(elem);
    }
  }
  remove(elementName) {
    if (document.getElementById(elementName)) {
      document.getElementById(elementName).style.display = 'none';
    }
  }

  tempV = new THREE.Vector3();
  updateLabels(camera, renderElement) {
    if (!this.#list.length) {
      return;
    }
    for (const info of this.#list) {
      const { position, elem } = info;
      this.tempV.copy(position);
      this.tempV.project(camera);
      const x = (this.tempV.x * 0.5 + 0.5) * renderElement.clientWidth;
      const y = (this.tempV.y * -0.5 + 0.5) * renderElement.clientHeight;

      elem.style.position = 'relative';
      elem.style.transform = `translate(${x}px,${y}px)`;
      elem.style.zIndex = ((-this.tempV.z * 0.5 + 0.5) * 100000) | 0;
    }
  }
}
/** @name 3D 文字实例 */
export const labelEl = new LabelElement();

/** @name 光影投射实例 */
export const raycasterFactory = (event, camera) => {
  const raycaster = new THREE.Raycaster();
  const pointer = new THREE.Vector2();
  pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  raycaster.setFromCamera(pointer, camera);
  return raycaster;
};

const random = (max = 0, min = 0) => {
  return parseFloat(((Math.random() * (max - min + 1) + min) / 100).toFixed(2));
};
/**
 * @name 摄像头分布的范围值
 * max: { x: 1.48, y: 0.65, z: 1.40 }
 * min: { x: -1.51, y: -0.29, z: -1.42 }
 */
function calcLoaction(index = -1) {
  let idx = parseInt(Math.random() * 3 + 1, 10) - 1;
  if (index !== -1) {
    idx = index;
  }
  const idxArr = [
    {
      max: 1.48,
      min: -1.51,
      label: 'x',
    },
    {
      max: 0.65,
      min: -0.29,
      label: 'y',
    },
    {
      max: 1.4,
      min: -1.42,
      label: 'z',
    },
  ];
  const { max, min, label } = idxArr[idx];
  let result = {};
  result[label] = random(max * 100, min * 100);

  const otherArr = idxArr.filter((_v, i) => i !== idx);
  const otherIdx = parseInt(Math.random() * 2 + 1, 10) - 1;
  otherArr.forEach(item => {
    if (otherIdx === 0) {
      result[item.label] = item.max;
    } else {
      result[item.label] = item.min;
    }
  });

  return result;
}
/** @name  让随机产生的数据，x，y，z都是平均的 */
const cameraPosition = (list = [], climbPos) => {
  let len = list.length;
  let xLen = Math.floor(len / 3);
  let yLen = Math.floor(len / 3);
  let zLen = len - xLen - yLen;
  const positionArray = [];
  for (let i = 0; i < xLen; i++) {
    positionArray.push(calcLoaction(0));
  }
  for (let i = 0; i < yLen; i++) {
    positionArray.push(calcLoaction(1));
  }
  for (let i = 0; i < zLen; i++) {
    positionArray.push(calcLoaction(2));
  }

  for (let i = 0; i < list.length; i++) {
    list[i].x = climbPos.x + positionArray[i].x;
    list[i].y = climbPos.y + positionArray[i].y;
    list[i].z = climbPos.z + positionArray[i].z;
  }
  return list;
};

/** @name 计算电箱的position */
const electricPosition = (a1 = 0, a2 = 0, axis, any, count = 0, main) => {
  // y + 0.21

  // // 减掉首尾，防止电箱贴近墙边
  a1 += 10;
  a2 -= 10;

  let offset = parseFloat(((a2 - a1) / (count + 1)).toFixed(2));

  let arr = a1 + offset;
  const res = [];
  while (arr < a2) {
    let obj = {
      x: 0,
      y: 0.21,
      z: 0,
      rotation: 0,
      main: false,
    };
    // rotation 180=Math.PI; 90=-Math.PI/2; -90=Math.PI/2
    if (axis === 'x') {
      obj.x = arr - 141;
      obj.z = (any > 0 ? any + 4 : any - 4) - 132;
      obj.rotation = obj.z < 0 ? Math.PI : 0;
    } else {
      obj.x = (any > 0 ? any + 4 : any - 4) - 141;
      obj.z = arr - 132;
      obj.rotation = obj.x < 0 ? -Math.PI / 2 : Math.PI / 2;
    }

    res.push({
      ...obj,
      x: parseFloat((obj.x / 100).toFixed(2)),
      z: parseFloat((obj.z / 100).toFixed(2)),
    });
    arr = arr + offset;
  }
  if (count !== res.length) {
    res.pop();
  }
  if (main) {
    const idx = Math.floor(res.length / 2);
    res[idx].main = true;
  }
  return res;
};

/** @name 计算电箱小视图的点的position */
const pointPosition = (a1 = 0, a2 = 0, axis, any, count = 0, main) => {
  // 减掉首尾，防止电箱贴近墙边
  a1 += 5;
  a2 -= 5;

  let offset = parseFloat(((a2 - a1) / (count + 1)).toFixed(2));

  let arr = a1 + offset;
  const res = [];
  while (arr < a2) {
    let obj = {
      x: 0,
      y: 0,
      color: 'green',
      r: 2,
      main: false,
      error: false,
      warn: false,
    };

    if (axis === 'x') {
      obj.x = arr;
      obj.y = any;
    } else {
      obj.x = any;
      obj.y = arr;
    }

    res.push(obj);
    arr = arr + offset;
  }
  if (count !== res.length) {
    res.pop();
  }
  if (main) {
    const idx = Math.floor(res.length / 2);
    res[idx].main = true;
    res[idx].color = 'green';
    res[idx].r = 3;
  }
  return res;
};

/** @name 电箱和小视图点的position */
const BoxAndPointPos = (mainLength = 0, childLength = 0) => {
  const res = [];
  const points = [];
  const xStart = 10; // 0
  const xEnd = 140; // 150
  const yStart = 10; // 0
  const yEnd = 131; // 141

  let locationBottom = -1;
  let size = Math.floor(childLength / 4);
  const xAxisStart = 0; // -1.51 -141 138
  const xAxisEnd = 279; // 1.48 -141 138
  const zAxisStart = 0; // -1.42 -132 130
  const zAxisEnd = 262; // 1.4 -132 130

  if (childLength < 4) {
    // 只绘制上，一条边
    res.push(
      ...electricPosition(
        xAxisStart,
        xAxisEnd,
        'x',
        zAxisEnd,
        childLength + 1,
        'main',
      ),
    );
    // svg 只绘制上，一条边
    points.push(
      ...pointPosition(xStart, xEnd, 'x', yEnd, childLength + 1, 'main'),
    );
  } else {
    // 上 左 下 右 四条边
    res.push(
      ...electricPosition(
        xAxisStart,
        xAxisEnd,
        'x',
        zAxisEnd,
        size + 1,
        'main',
      ),
    );
    res.push(...electricPosition(zAxisStart, zAxisEnd, 'z', xAxisStart, size));
    // const len = res.length;
    res.push(...electricPosition(xAxisStart, xAxisEnd, 'x', zAxisStart, size));
    // if (mainLength > 1) {
    //   locationBottom = Math.floor((res.length - len) / 2) + len - 1;
    // }
    res.push(
      ...electricPosition(
        zAxisStart,
        zAxisEnd,
        'z',
        xAxisEnd,
        childLength - size * 3,
      ),
    );

    // eslint-disable-next-line no-console
    console.log(mainLength);
    // svg 上 右 下 左 四条边
    points.push(...pointPosition(xStart, xEnd, 'x', yEnd, size + 1, 'main'));
    points.push(...pointPosition(yStart, yEnd, 'y', xStart, size));
    points.push(...pointPosition(xStart, xEnd, 'x', yStart, size));
    points.push(
      ...pointPosition(yStart, yEnd, 'y', xEnd, childLength - size * 3),
    );
  }
  let count = 0;
  points.forEach(val => {
    if (!val.main) {
      val.cid = ++count;
    } else {
      val.cid = -1;
    }
  });
  return {
    res,
    points,
    locationBottom,
  };
};

/** @name 模型加载 */
class LoadModel {
  gltfLoader;
  constructor() {
    this.gltfLoader = this.initGltfLoader();
  }
  // 初始化DRACO
  initGltfLoader() {
    const gltfLoader = new GLTFLoader();
    const draco = new DRACOLoader();
    draco.setDecoderPath('/static/three/draco/gltf/');
    draco.setDecoderConfig({ type: 'js' });
    draco.preload();
    gltfLoader.setDRACOLoader(draco);
    return gltfLoader;
  }

  /** @name 获取oss的API */
  getOssHost() {
    return getIotOssHost();
  }

  // 获取OSS
  ossHost = '';
  /** @name 设置oss路径 */
  setHost(host) {
    this.ossHost = host;
  }
  /** @name 模型oss地址 */
  getFilePath(fileName) {
    return `${this.ossHost}/sd110/threeModel/${fileName}`;
  }

  /**
   * @name load模型
   * @param filePath
   * @param fn
   * @param xhrFn
   */
  async loadModel(filePath, fn, xhrFn) {
    const path = this.getFilePath(filePath);
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(
        path,
        s => {
          const mod = fn ? fn(s.scene) : s.scene;
          resolve(mod);
        },
        xhr => {
          xhrFn ? xhrFn(xhr) : null;
        },
        err => reject(err),
      );
    });
  }

  /** @name 楼层优化模型 */
  floor(scene, array = []) {
    // egg: https://blog.csdn.net/sinat_35823840/article/details/112857447
    const layer = scene.clone();
    const floor = layer.children[0].clone();
    const top = layer.children[1].clone();
    const geometries = [floor.geometry, top.geometry];
    const materials = [floor.material, top.material];
    let geometry = mergeBufferGeometries(geometries, true);
    geometry.computeBoundingBox();
    const mesh = new THREE.InstancedMesh(geometry, materials, array.length);
    let transform = new THREE.Object3D();
    array.forEach((item, index) => {
      transform.name = item.name;
      transform.position.set(item.x, item.y, item.z);
      transform.updateMatrix();
      mesh.setMatrixAt(index, transform.matrix);
    });
    mesh.userData.array = array;
    transform = null;
    return mesh;
  }

  /** @name 爬架简易模型 */
  climb(array = []) {
    const boxGeometry = new THREE.BoxGeometry(2.9, 1, 2.8, 5, 3, 5);
    const material = new THREE.MeshLambertMaterial({
      color: '#0051ff',
      emissive: '#0051ff',
      wireframe: true,
    });

    const mesh = new THREE.InstancedMesh(boxGeometry, material, array.length);
    let transform = new THREE.Object3D();
    array.forEach((item, index) => {
      transform.name = item.name;
      transform.position.set(item.x, item.y, item.z);
      transform.updateMatrix();
      mesh.setMatrixAt(index, transform.matrix);
    });
    mesh.userData.array = array;
    transform = null;
    return mesh;
  }

  /** @name 摄像头模型 */
  camera(model, array = [], climbPos) {
    const list = cameraPosition(array, climbPos);

    return list.map(item => {
      const ca = model.clone();
      ca.name = `camera`;
      ca.rotation.y = Math.PI / 2;
      ca.position.set(item.x, item.y, item.z);
      ca.visible = false;
      ca.userData.buildId = item.pkBuildingId;
      ca.userData.cameraId = item.pkCameraId;
      return ca;
    });
  }

  /** @name 电箱模型 */
  electric(
    target = { x: 0, y: 0, z: 0 },
    mainLength = 0,
    childLength = 0,
    eleMain,
    eleChild,
  ) {
    const { res, points, locationBottom } = BoxAndPointPos(
      mainLength,
      childLength,
    );

    let count = 0;
    const electricList = res.map((ele, idx) => {
      if (ele.main || (idx === locationBottom && locationBottom !== -1)) {
        const main = eleMain.clone();
        main.position.set(target.x + ele.x, target.y + 0.26, target.z + ele.z);
        main.scale.set(3, 3, 3);
        main.name = 'electric-box';
        if (idx === locationBottom) {
          main.rotation.y = Math.PI;
        }
        return main;
      } else {
        const child = eleChild.clone();
        child.position.set(
          target.x + ele.x,
          target.y + ele.y,
          target.z + ele.z,
        );
        child.scale.set(3, 3, 3);
        if (ele.rotation !== 0) {
          child.rotation.y = ele.rotation;
        }
        child.userData.count = ++count;
        child.name = 'electric-child';
        return child;
      }
    });

    const mainEle = electricList.find(v => v.name === 'electric-box');

    return {
      electricList,
      mainEle,
      points,
    };
  }
}
/** @name 模型加载实例 */
export const load = new LoadModel();

/** @name 弹出层的element或者html  */
export const alertEle = {
  /** @name 楼层弹出层element */
  build: (item, $style) => {
    const colorObj = {
      WAIT_ENTER: '#f59a23',
      DEVICE_BIND: '#06ff79',
      RISE: '#06ff79',
      BUILD_LOADING: '#ffff00',
      QUIT: '#aaaaaa',
    };
    const buildingText = {
      WAIT_ENTER: '待进场',
      DEVICE_BIND: '已部署',
      RISE: '提升',
      BUILD_LOADING: '待绑定',
      QUIT: '已退场',
    };
    const el = document.createElement('div');
    el.innerHTML = `
      <div class="${$style.buildingInfo}">
        <div class="${$style.title}">
            <span>楼栋信息</span>
        </div>
        <div class="${$style.list}">
            <span class="${$style.text}">楼栋：</span>
            <span>${item.buildingName}</span>
        </div>
        <div class="${$style.list}">
            <span class="${$style.text}">进度：</span>
            <span style="color: ${colorObj[item.process]}">
                ${item.process ? buildingText[item.process] : '-'}
            </span>
        </div>
        <div class="${$style.list}">
            <span class="${$style.text}">规划：</span>
            <span>${`${item.buildingNumber}层/${item.buildingHeight}米`}</span>
        </div>
        <div class="${$style.list}">
            <span class="${$style.text}">当前：</span>
            <span>${`${item.currentLayer}层/${item.currentHeight}米`}</span>
        </div>
      </div>
    `;
    return el;
  },
  /** @name 电箱弹出层html */
  electric: (item, $style, flag, id = 'electricIcon') => {
    let str = `<div class="${$style.electricInfo}">`;
    if (flag) {
      str += `
        <i id="${id}" class="TC tc-icon-shuangjiantou ${$style.icon}"></i>
      `;
    }
    str += `
      <div class="${$style.list}">
        <span class="${$style.text}">类型：</span>
        <span>${item.type}</span>
      </div>
      <div class="${$style.list}">
        <span class="${$style.text}">时间：</span>
        <span>${item.time}</span>
      </div>
      <div class="${$style.list}" style="display: ${
      item.desc ? 'block' : 'none'
    }">
        <span class="${$style.text}">详情：</span>
        <span>${item.desc}</span>
      </div>
    </div>`;
    return str;
  },
};

/** @name （小视图）主、分控箱故障预警分布 */
export function getBoxDistribution(data, select, array) {
  if (Object.keys(data.deviceAram).length === 0) {
    return false;
  }
  let list = [];
  if (data.deviceAram[select.deviceName]) {
    list = data.deviceAram[select.deviceName];
  } else {
    select.devices.forEach(item => {
      if (data.deviceAram[item.deviceName]) {
        list = data.deviceAram[item.deviceName];
      }
    });
  }

  const hasWarm = list.find(
    v =>
      v.sensorType === 'TOTAL_LOAD' ||
      v.sensorType === 'TOTAL_CURRENT' ||
      v.sensorType === 'WEATHER',
  );

  const hasError = list.find(v => v.sensorType === 'MAIN_CONTROL_BOX_STOP');

  const arr = list.filter(
    v =>
      v.sensorType !== 'TOTAL_LOAD' ||
      v.sensorType !== 'TOTAL_CURRENT' ||
      v.sensorType !== 'WEATHER' ||
      v.sensorType !== 'MAIN_CONTROL_BOX_STOP',
  );

  const map = new Map();
  arr.forEach(item => {
    if (!map.has(item.cid)) {
      map.set(item.cid, {
        error: item.eventType === 'ERROR' ? true : false,
        warn: item.eventType === 'WARN' ? true : false,
      });
    } else {
      const has = map.get(item.cid);
      if (item.eventType === 'ERROR') {
        has.error = true;
      }
      if (item.eventType === 'WARN') {
        has.warn = true;
      }
      map.get(item.cid, {
        error: has.error,
        warn: has.warn,
      });
    }
  });

  array.forEach(item => {
    if (item.main) {
      item.error = hasError ? true : false;
      item.warn = hasWarm ? true : false;
    } else {
      if (map.has(item.cid)) {
        const has = map.get(item.cid);
        item.error = has.error;
        item.warn = has.warn;
      }
    }
  });
}

/** @name 主控箱弹出层信息处理 */
export function getMessage(message) {
  let item = {
    type: '',
    time: '',
    desc: '',
  };
  item.desc = message.content;
  let type = undefined;
  if (message.eventType === 'ERROR') {
    type = errorList.find(v => v.value === message.sensorType);
  } else {
    type = warnList.find(v => v.value === message.sensorType);
  }
  item.type = type ? type.label : '';
  item.time = dayjs(message.time).format('YYYY-MM-DD HH:mm:ss');
  return item;
}

/** @name 时间格式补零（01） */
export function padZero(val) {
  return ('00' + val).slice(-2);
}
